import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Navigation from "../components/navigation"

import { useSpring, animated } from 'react-spring'

const MainIndex = ({ data, location }) => {
  const titleAnimation = useSpring({
    to: { marginLeft: 0 },
    from: { marginLeft: -1000 },
    delay: 200,
  });

  const navAnimation = useSpring({
    to: { opacity: 1 },
    from: { opacity: 0 },
    delay: 500,
  });

  return (
    <Layout location={location} hideFooter={true}>
      <Seo title="hi" />
      <div className="index-container">
        <animated.div style={titleAnimation}>
          <h1 className="index-header">hi, i'm pattra.</h1>
          <h2 className="index-subheader">i'm an artist, writer, and engineer.</h2>
          <div className="index-subsubheader">you can find me @ohpatpatt on <a href="https://instagram.com/ohpatpatt">Instagram</a> and <a href="https://twitter.com/ohpatpatt">Twitter</a>.</div>
        </animated.div>
        <animated.div style={navAnimation}>
          <Navigation />
        </animated.div>
      </div>
    </Layout>
  )
}

export default MainIndex;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
